.blogHome {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100vw;
    margin: 0px auto;
}

.blogHero {
    display: flex;
    width: 100%;
    height: 450px;
}

.blogHero > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.postGallery {
    display: flex;
    max-width: 1000px;
    width: 80vw;
    margin: 50px auto;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blogPCard {
    display: flex;
    flex-direction: column;
    width: 48%;
    padding: 10px;
    border: 1px rgb(168, 168, 168) solid;
    border-radius: 10px;
    margin-bottom: 15px;
    gap: 10px;
    text-align: left;
}

.blogPCard > a {
    text-decoration: none;
    color: inherit;
}

.blogI {
    width: 100%;
    height: 250px;
}

.blogI > img {
    width: 100% ;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.blogTag {
    font-size: 15px;
    font-weight: 600;
    margin: 5px 0px;
    text-transform: capitalize;
    padding: 5px;
    border-radius: 5px;
    background-color: #fb8b033f;
    width: fit-content;
}

.blogTitle {
    font-size: 18px;
    font-weight: 700;
}

.bcAuthor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.bcAuthor > img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.bcName, .bcDate {
    font-size: 15px;
    font-weight: 300;
}

.moreBtn {
    font-size: 15px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid gray;
    width: fit-content;
    margin:10px auto;
}

/* single post*/

.postWrapper {
    display: flex;
    width: 100vw;
    max-width: 1200px;
    margin: 0px auto;
}

.singlePost {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
    gap: 20px;
    text-align: left;
}

.spAtag {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 5px;
    border-radius: 5px;
    background-color: #fb8b03d7;
    width: fit-content;
}

.spAtitle {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
}

.spAcard {
    display: flex;
    align-items: center;
    gap: 30px
}

.spAcard > h5 {
    font-size: 15px;
    font-weight: 400;
}

.spAcard > img {
    height: 50px;;
    width: 50px;
    border-radius: 50%;
}

.spAimgHolder {
    width: 100%;
    height: 350px;
}

.spAimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width:900px) {
    .postGallery {
        width: 95vw;
        margin: 50px auto;
    }
}

@media only screen and (max-width:700px) {
    .postGallery {
        /* width: 100vw; */
        margin: 30px auto;
    }

    .blogI {
        height: 200px;
    }

    .blogTag {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    .blogTitle {
        font-size: 15px;
        font-weight: 700;
    }
    
    .bcName, .bcDate {
        font-size: 13px;
        font-weight: 300;
    }
}

@media only screen and (max-width:500px) {
    .blogPCard {
        width: 100% !important;
        margin: 5px auto !important;
    }
}