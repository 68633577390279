/* ourWork.css */

/* General styling for the Our Work page */
.our-work-page {
    font-family: Arial, sans-serif;
    color: #333; /* Neutral color for text to blend with the theme */
    background-color: #fbf3cc; /* Light theme color for background */
    /* padding: 20px; */
    width: 100vw;
    max-width: 1200px;
    margin: 0px auto;
}


.our-work-header {
    text-align: center;
    margin-bottom: 40px;
}

.our-work-header h1 {
    color: #fa8c06; /* Primary theme color for headings */
    font-size: 36px;
    margin-bottom: 10px;
}

.our-work-header p {
    font-size: 18px;
    color: #666; /* Softer color for subtext */
}

/* Styling for the projects grid */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid layout */
    gap: 20px;
    place-items: center;
}

/* Styling for individual project cards */
.project-card {
    background-color: #ffffff; /* White background for cards */
    border: 1px solid #ddd; /* Light border for definition */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a polished look */
    overflow: hidden;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px); /* Slight lift on hover */
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.project-info {
    padding: 20px;
    text-align: center;
}

.project-info h3 {
    color: #fa8c06; /* Primary theme color for project titles */
    font-size: 24px;
    margin-bottom: 10px;
}

.project-info p {
    color: #333; /* Darker text for readability */
    font-size: 16px;
    margin: 0;
}

.singleProjectsHome {
    display: flex;
    flex-direction: column;
    width: 95vw;
    max-width: 1200px;
    margin: 10px auto;
}

.singleProjectsHome > img {
    height: 400px;
}

.loaderDiv {
    width: 80vw;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectExcerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
  }
