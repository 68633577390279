@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.3s ease;
    text-decoration: none;
}

:root {
    --yellow: #faca24;
}

html {
    /* font-size: 62.5%; */
    scroll-behavior: smooth;
    overflow-x: hidden;
    scroll-padding-top: 7rem;
}

html::-webkit-scrollbar {
    width: 1.4rem;
}

html::-webkit-scrollbar-track {
    background: #222;
}

html::-webkit-scrollbar-thumb {
    background: var(--yellow);
}

::selection {
    /* background: var(--yellow); */
    color: #fff;
}

.about {
    max-width: 120rem;
    /* min-height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Center the container within the body */
}

.about-title {
    font-size: 4rem;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3rem;
}

.underline-shadow {
    width: 100%; /* Make the underline span 100% width */
    height: 0.5rem;
    background-color: var(--yellow);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 2rem; /* Space after the underline */
    margin-left: auto;
    margin-right: auto;
}

.aboutus--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start (left) */
    gap: 5rem;
    width: 100%;
    max-width: 100rem; /* Restrict the width for better centering */
    flex-wrap: wrap; /* Ensure responsiveness */
     /* Center the wrapper */
}

.aboutus--container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* Align content to the start (left) */
    padding: 2rem;
    /* background: #fff; */
    border-radius: 1rem;
    max-width: 48%; /* Prevent containers from getting too wide */
}

.aboutus--image {
    width: 100%;
    height: 40rem;
    border-radius: 1rem;
    margin-top: 2rem;
}

.tag {
    background: var(--yellow);
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.aboutus--container h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.aboutus--container .about--content {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    text-align: left; /* Ensure content is aligned to the left */
}

@media (max-width: 700px) {
    .aboutus--wrapper{
        flex-direction: column;
    }
    .aboutus--container {
        max-width: 95%;
    }

    .aboutus--container h1{
        text-align: left;
    }
}
