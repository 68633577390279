* a {
    text-decoration: none;
}
.navMain {
    display: flex;  
    flex-direction: column;
    height: 120px;
    align-items: center; 
    width: 100vw;
    max-width: 1200px;
    margin: 0px auto;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    /* background-color: #ffffff; */
    z-index: 1;
}

.topNav {
    display: flex;
    width: 100%;
    height: 80px;
}

.topNav > div {
    display: flex;
    align-items: center;
    width: 25%;
    gap: 5px
}

.topNav > div > img {
    height: 35px;
    width: auto;
    margin-left: auto;
}

.navLogo {
    height: 55px;
    width: auto;
}

.topNavA > a {
    margin-left: auto;
}

.topInfo, .cCname {
    margin-right: auto;
}

.cCname > a {
    color: #ffffff;
}

.infoValue {
    text-wrap: nowrap;
}

.infoValue > a {
    color: #ffffff;
    height: 20px;
}

.topNavA {
    background-color: black;
    border-radius: 0px 0px 0px 10px;
    display: flex;
    align-items: center !important;
}

.topNavD {
    background-color: black;
    border-radius: 0px 0px 10px 0px;
}

.topNavB, .topNavC, .topNavD {
    background-color: rgba(0, 0, 0, 0.8);
}

.cCname {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
}

.infoName {
    font-size: 15px;
    color: #fb8c03;
}

.infoValue {
    font-size: 15px;
    color: #ffffff;
}

.nav {
    display: flex;
    width: 95%;
    border: 2px solid #fb8c03;
    border-radius: 15px;
    margin-top: -10px;
    background-color: #ffffff;
}

.navLinks {
    display: flex;
    width: 95%;
    height: 50px;
    margin: auto;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.navlink {
    color: black;
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    border: 5px solid #ffffff;
}

.navSearch {
    margin-left: auto;
}

.navSearch > img {
    height: 30px;
    width: auto;
}

.topNavA > img {
    width: auto;
    height: 50px;
}

.logo{
    margin-right: auto;
}

.logo > img {
    width: auto;
    height: 60px;
}

.menuToggle {
    width: 0px;
    height: 0px;
    /* background-color: #2C5AA7; */
}

.navlinks {
    display: flex;
    justify-content: space-around;
    width: 70%;
    align-items: center;
}

.navlink.active {
   /* border-radius: 2px solid #fb8c03; */
   color: #fb8c03;
}

.navlink.active:hover {
    color: black;
}

/* css tri*/

.up-arrow {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;

    border-bottom: 50px solid green;

    margin: 2rem;
}


.navlink:hover {
    background-color: #fb8c03;
    border: 5px solid #fb8c03;
    border-radius: 5px;
}

.search > img {
    width: 25px;
    height: auto;
}

@media only screen and (max-width:1050px) {
    .topNavD > .topInfo {
        overflow: hidden;
    }

    .topNavC > .topInfo {
        max-width: 150px;
    }

    .topNavD > .topInfo > .infoValue {
        margin-bottom: -4px;
        display: inline-block;
        color: #FFF;
        padding-left: 100%;
        animation: move 15s linear infinite;
    }
    @keyframes move {
        0%   { transform: translate(0, 0); }
        100% { transform: translate(-100%, 0); }
    }
}

@media only screen and (max-width:930px) {
    .topNav > div > img {
        margin-left: 10px;
    }

    .topInfo, .cCname {
        /* margin-right: 10px; */
    }

    .infoValue {
        overflow: hidden;
        margin-right: 10px;
    }

    .infoValue > a {
        display: inline-block;
        color: #FFF;
        padding-left: 100%;
        animation: move 15s linear infinite;
    }
    @keyframes move {
        0%   { transform: translate(0, 0); }
        100% { transform: translate(-100%, 0); }
    }
}

@media only screen and (max-width:830px) {
    .navlink {
        font-size: 13px;
    }
}


@media only screen and (max-width: 700px) {

    .navlink {
        font-size: 15px;
    }

    .search {
        display: none;
    }

    .nav {
        border: none;
        width: 100vw;
    }

    .navSearch {
        display: none;
    }

    .topNav {
        background-color: #ffffff;
        height: 70px;
        opacity: 80%;
    }

    .topNavA {
        width: 70% !important;
        z-index: 2;
        height: 70px;
    }

    .topNavB, .topNavC, .topNavD {
        display: none !important;
    }

    .menuToggle {
        height: 30px;
        width: 30px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: auto; /* Changed from fixed margin */
        top: 20px; /* Removed negative margin */
        right: 20px;
        position: fixed;
        z-index: 2;
    }

    .navLinks {
        flex-direction: column;
        padding-left: auto;
        padding-right: auto;
        width: 100vw;
        gap: 5px;
        height: fit-content;
        /* z-index: 2; */
        background-color: #ffffff;
    }

    .navLinks.false {
        display: none;
    }

    .navLinks.open {
        z-index: 2;
    }

    .bar {
        width: 25px;
        height: 5px;
        background-color: #000000;
        transition: transform 0.3s ease;
    }
    
    .clicked:nth-child(1) {
        transform: rotate(45deg) translate(6px, 2px);
        transition: transform 0.3s ease;
    }
    
    .clicked:nth-child(2) {
        display: none;
        transition: transform 0.3s ease;
    }

    .clicked:nth-child(3) {
        transform: rotate(-45deg) translate(9px, -2px);
        transition: display 0.3s ease;
    }
    
} 