.services {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  flex-wrap: wrap;
  gap: 25px;
}
.services-card {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  width: 360px;
  height: 350px;
  border-radius: 5px;
  padding: 10px;
}
.services-icon {
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  font-size: 50px;
}

.services-card .services-card-title {
  color: rgb(247, 240, 240);
  text-align: center;
  font-size: 25px;
}
.services-card .services-card-body {
  color: rgb(246, 246, 246);
  text-align: center;
  font-size: 17px;
  display: flex;
  flex-direction: column;
}

.services-card .services-card-body .services-card-text {
  text-align: start;
}

.fg-orange {
  color: #fb8c03;
}

.header {
  color: #1b1919;
  text-align: center;
  padding: 20px;
  /* margin: 30px auto; */
}

@media only screen and (max-width: 600px) {
  .services {
    margin: 0;
  }
  .services-card {
    width: auto;
    max-width: 350px;
  }
}
