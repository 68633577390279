.careersMain {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 1200px;
    align-items: center;
    margin: 0px auto;
}

.careersHeader {
    height: 100px;
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(251, 140, 3, 0.527),rgba(251, 140, 3, 0.5)) ,url("./assets/ab1.jpg");
    align-items: center;
}

.careersContent, .careersHero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px; 
}

.points, .point{
    display: flex;
    align-items: center;
}

.points {
    flex-direction: column;
}

.point {
    align-items: center;
    gap: 10px;
}

.point > img {
    width: 20px;
    height: 20px;
}


