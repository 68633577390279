.header {
  /* margin-bottom: 20px; */
  font-size: 50px;
}

.faqs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.container {
  max-width: 600px;
}

.container .wrapper {
  border-bottom: 1px solid black;
  overflow: hidden;
}

.container .wrapper .question-container {
  width: 100%;
  text-align: left;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  background-color: rgb(216, 214, 214);
  border-radius: 5px;
}

.container .question-container.active {
  color: #fb8c03;
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.container .wrapper .question-container:hover {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.container .wrapper .arrow {
  font-size: 2rem;
  transition: 0.4s ease-in-out;
}

.container .arrow .active {
  rotate: 180deg;
  color: #fb8c03;
}

.wrapper .answer-container {
  padding: 0 1rem;
  transition: height 0.4s ease-in-out;
}

.container .wrapper .answer-content {
  padding: 1rem 0;
  font-size: 20px;
  font-style: italic;
}

.text-lg {
  font-size: 30px;
}

.bg-orange {
  background-color: #fb8c03;
}

.fg-orange {
  color: #fb8c03;
}

.m-4 {
  margin-left: 20px;
  margin-right: 20px;
}

.email-info {
  background-color: #f8f8f8;
  padding: 12px;
  border-radius: 5px;
}

.email-info > a {
  color: inherit;
}

.d-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .container {
    width: auto;
    margin: 10px;
  }
}
