.landingMain {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 1200px;
    margin-top: -45px;
    margin-left: auto;
    margin-right: auto;
}

.heroImage {
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) ,url("../landingPage/assets/construction-site.webp");
    height: 700px;
    width: 100%;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero {
    position: relative;
    text-align: center;
    color: #ffff;
}

.heroText {
    position: absolute;
    top: 200px;
    left: 50px;
    width: 50%;
    text-align: left;
}

.headerMain {
    text-transform: capitalize;
    font-size: 50px;
    font-weight: 700;
}

.headerB {
    text-transform: capitalize;
    font-size: 35px;
    font-weight: 700;
}

.content, .infoContent, .servicesContent {
    font-size: 20px;
    font-weight: 300 !important;
    /* text-transform:uppercase; */
}

.servicesTitle, .stepName {
    font-size: 20px;
    font-weight: 450;
    margin: 0px;
    color: #ffffff;
}

.servicesContent{
    margin: 0px;
}

.infoContent {
    margin: 0px;
    color: #ffff;
}

.infoTitle {
    font-size: 20px;
    font-weight: 400;
    color: #fb8c03;
    margin: 0px;
}

.blogName {
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0px;
}

.button {
    background-color: #fb8c03;
    padding: 8px;
    border-radius: 8px;
    text-transform: capitalize;
    width: fit-content;
    color:black;
    font-size: 15px;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.lowerCaps {
    text-transform: lowercase;
}

.aboutH{
    display: flex;
    justify-content: space-around;
    margin: 50px auto;
}

.aboutHA {
    width: 30%;
}

.aboutHBB {
    width: 60%;
    display: flex;
    justify-content: space-around;
}


.aboutHB, .aboutHC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.ImgTint {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ImgTint > img{
    width: 95%;
    height: 95%;
    object-fit: cover;
    border-radius: 25px;
    margin: auto;
}

.infoCard {
    width: 90%;
    height: 20%;
    display: flex;
    align-items: center;
    background-color: black;
    border-radius: 15px;
}

.imgHolder {
    width: 30%;
    margin-left: auto;
    position: relative;
}

.imgHolderTint {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    position: absolute;
    top: 0px;
    display: flex;
}

.tintDivOne {
    border-right: 100px solid #fb8b0393;
    border-top: 100px solid transparent;
    border-radius: 10px;
    align-self:flex-end;
    margin-left: auto;
}

.tintDivTwo {
    border-left: 100px solid #fb8b0393;
    border-top: 100px solid transparent;
    border-radius: 10px;
    align-self: flex-end;
}

.imgHolder > img {
    width: 50px;
    height: 50px;
}

.infoHolder {
    margin-right: auto;
}

.feature {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.feature > img {
    width: 25px;
    height: 25px;
}

.feat {
    font-size: 20px;
    font-weight: 500;
    margin: 0px;
}

.servicesH {
    display: flex;
    gap: 5%;
    color: #ffffff;
    padding: 50px 0px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url("../landingPage/assets/ab1.jpg");
}

.cName {
    font-size: 20px;
    font-weight: 500;
}

.cOrg {
    margin-bottom: 20px;
}

.cTesti {
    line-height: 40px;
}

.servicesHA {
    width: 38%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
}

.servicesHB {
    width: 57%;
    margin-right: 0px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: column;
}

.servicesControl {
    height: 50px;
}

.fs, .fsB {
    margin-top: 5px !important;
    cursor: pointer;
}

.button > a {
    text-decoration: none;
    color: inherit;
}

.scroller {
    display: flex;
    overflow-x: auto; 
}


.servicesCard {
    min-width: 280px;
    width: 40%;
    background-color: black;
    border: 1px solid grey;
    border-radius: 15px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    text-align: left;
    justify-content: space-evenly;
    margin-right: 30px;
    box-sizing: border-box;
}

.servicesCard > img {
    width: 50px;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
}

.sReadMore {
    display: flex;
    gap: 10px;
    align-items: center;
}

.sReadMore > img {
    width: 15px;
    height: 20px;
}

.worksH {
    display: flex;
    padding-bottom: 50px;
}

.stepHiglight {
    width: 50px;
    height: 40px;
    background-color: #fb8c03;
    /* z-index: 1; */
    margin-bottom: -15px;
    border-radius: 0% 0% 50% 50%;
}

.worksHA {
    width: 50%;
}

.worksHB {
    display: flex;
    width: 50%;
    position: relative;
}

.worksHBbg {
    width: 40%;
    height: 40%;
    background: #fb8c03;
    align-self: first baseline;
    right: 0;
    top: 0;
    z-index: -1;
    position: absolute;
}

.video {
    width: 80%;
    height: 80%;
    background-color: aquamarine;
    align-self: flex-end;
    margin-left: 50px;
    margin-bottom: 20px;
    border-radius: 15px;
}

.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step {
    background: black;
    display: flex;
    width: 90%;
    gap: 10px;
    align-items: center;
    border-radius: 15px;
    padding: 20px 0px;
    z-index: -1;
}

.step > img {
    margin-left: auto;
    height: 40px;
    width: auto;
}

.step > div {
    margin-right: auto;
}

.projectsH {
    display: flex;
    flex-direction: column;
}

.projectsHA {
    padding: 70px 30px;
    text-align: left;
    background-image: linear-gradient(rgba(240, 234, 157, 0.527),rgba(240, 234, 157, 0.527)), url("../landingPage/assets/ab1.jpg");
}

.projectsSliderControls {
    display: flex;
    gap: 10px;
}

.projectsSliderControls > span {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #fb8c03;
    display: flex;
    margin-top: -30px;
}

.projectsSliderControls > span > img {
    width: 20px;
    height: auto;
    margin: auto;
}

.memberSliderControls {
    margin-top: 30px;
}

.fs {
    margin-left: auto;
}

.fsB {
    margin-right: 50px;
}

.rot {
    rotate: 180deg;
}

.projectsHB {
    display: flex;
    margin-top: -50px;
    overflow-x: auto;
    overflow-y: hidden;
}

.projectsCard {
    width: 30%;
    min-width: 300px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.projectsCard > a > img {
    width: 90%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

.pcTcontent {
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
    font-weight: 600 !important;
    color: rgb(44, 43, 43);
}

.hollowB {
    background-color: #ffffff;
    border:1px solid #fb8c03;
    margin: 20px auto;
}

.teamH {
    display: flex;
    gap: 2%;
    margin: 50px auto;
    width: 100%;
}

.teamHA {
    width: 34%;
    text-align: left;
    margin-left: 2%;
}

.teamHB {
    display: flex;
    width: 62%;
    overflow-x: auto;
    overflow-y: hidden;
}

.blackB {
    color: #ffffff;
    background-color: black;
}

.teamCard {
    width: 45%;
    min-width: 250px;
    position: relative;
    display: flex;
}

.memberBg > img {
    width: 90%;
    height: 400px;
    margin-top: auto;
    object-fit: cover;
}

.memberInfo {
    position: absolute;
    top:100;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.infoUpper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.infoLower {
    width: 95%;
    background: black;
    color: #ffff;
    padding: 10px 0px;
    border-radius: 10px;
}

.socialsInfo {
    display: none;
}

.colorDiv {
    border-top: none;
    border-left: none;
    border-right: 100px solid #fb8b0393;
    border-bottom: 100px solid transparent;
    border-radius: 10px;
    position: relative;
    top: -20;
    z-index: 0;
    margin-left: auto;
}

.testimonialsH {
    display: flex;
    flex-direction: column;
}

.testimonialsHA {
    background-image: linear-gradient(rgba(240, 234, 157, 0.527),rgba(240, 234, 157, 0.527)), url("../landingPage/assets/ab1.jpg");
    padding: 50px 30px 120px 30px;
}

.testimonialsHB {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: -100px;
}

.testiBg > img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    background-repeat: no-repeat;
    margin-left: 50px;
    border-radius: 15px;
}

.testiFg {
    position: absolute;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    /* overflow-x: scroll; */
}

.testiCard {
    width: 75%;
    background-color: #fff3cf;
    margin: auto; 
    padding: 30px 0px;
    border-radius: 15px;
}

.testiCard > img {
    width: 80px;
    height: 80px;
}

.blogH {
    display: flex;
    flex-direction: column;
    background-color: #fff3cf;
    padding: 70px 30px;
}

.blogHA {
    text-align: left;
}

.blogHB {
    width: 100%;
    justify-content: space-around;
    display: flex;
    overflow-x: auto;
}

.blogCard {
    min-width: 300px;
    margin: 0px 10px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    text-align: left;
}

.blogCard > a {
    color: inherit;
}

.blogCard > img, .bcImg {
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
}

.blogRM {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.blogRM > img {
    height: 25px;
}

.buttonB {
    margin: 30px auto;
}

.clients {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
}

.newsletter {
    display: flex;
    /* flex-direction: column; */
    background: black;
    color: #ffffff;
}

.newsletter > img {
    width: 50%;
    height: 400px;
    object-fit: cover;
}

form {
    display: flex;
    align-items: center;
}

form > div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin: 10px auto;
}

label, input {
    padding: 10px;
    font-size: 20px;
}

input {
    border: 1px solid #fb8c03;
    border-radius: 10px 0px 0px 10px;
    width: 60%;
    background: black;
    color: #fb8c03;
    margin-left: auto;
}

label {
    background: #fb8c03;
    border-radius: 0px 10px 10px 0px;
    margin-right: auto;
}


/* footer */

.footer {
    display: flex;
    flex-direction: column;
    padding: 100px 40px 10px 40px;
    background: black;
    color: #ffffff !important;
}

.footerOne {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.footerA, .footerC {
    width: 28%;
}

.footerB {
    width: 15%;
}

.footerD {
    width: 23%;
}

.footerAA {
    display: flex;
    align-items: center;
}

.footerAA > img {
    width: 100px;
    height: auto;
}

.footerAA > div {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 700;
}

.footerSocials {
    display: flex;
    gap: 5px;
}

.footerSocials > img {
    width: 20px;
    height: 20px;
    background: #fb8c03;
    border-radius: 50%;
    padding: 5px;
}

.footerHead {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    margin-bottom: 20px;
}

.footerInfo {
    display: flex;
    gap: 7px;
    align-items: center;
    margin-bottom: 20px;
}

.footerInfo > img {
    width: 20px;
}

.footerD > .footerInfo > img {
    width: 20px;
    padding: 5px;
    background: #fb8c03;
    border-radius: 50%;
}

.imgInfo {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover;
}

.copyright > div > a {
    text-decoration: none;
    color: #fb8c03;
}

.copyright{
    margin: 10px auto;
}

.footerTwo {
    display: flex;
    text-align: center;
    padding-top: 50px;
}

.footerInfo > div > a {
    text-decoration: none;
    color: #ffffff
}

/* loading spinner*/

.loadingSpinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100vw;
}
.loadingSpinner {
    width: 50px;
    --b: 8px; 
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(#0000 10%,#fb8c03) content-box;
    -webkit-mask:
      repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
      radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
    -webkit-mask-composite: destination-in;
            mask-composite: intersect;
    animation:l4 1s infinite steps(10);
    margin: auto;
  }
  @keyframes l4 {to{transform: rotate(1turn)}}

  /* pagenotfound */

  .pnfWrapper {
    display: flex;
    width: 100vw;
    max-width: 1200px;
    margin: 0px auto;
    height: 500px;
  }

  .pnfImg {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    object-fit: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(rgba(251, 139, 3, 0.178),rgba(251, 139, 3, 0.192)) ,url("../../assets/133396.webp");
  }

  .pnfText {
    display: flex;
    margin: auto;
  }

  .pnfText > h1 > a {
    color: #fb8c03;
  }


@media only screen and (max-width: 1050px) {
    .headerMain {
        text-transform: capitalize;
        font-size: 42px;
        font-weight: 700;
    }
    
    .headerB {
        text-transform: capitalize;
        font-size: 30px;
        font-weight: 700;
    }
    
    .content, .infoContent, .servicesContent {
        font-size: 18px;
        font-weight: 300 !important;
        /* text-transform:uppercase; */
    }
    
    .servicesTitle, .stepName {
        font-size: 18px;
        font-weight: 450;
        margin: 0px;
        color: #ffffff;
    }
    
    .infoTitle {
        font-size: 18px;
        font-weight: 400;
        color: #fb8c03;
        margin: 0px;
    }
    
    .blogName {
        font-size: 21px;
        font-weight: 500;
        margin: 10px 0px;
    }

    .footerHead {
        font-size: 18px;
    }

    .feat {
        font-size: 18px;
    }

    .footerAA > div {
        font-size: 22px;
    }

    .footer {
        padding: 100px 20px 10px 20px;
    }

}

@media only screen and (max-width: 850px) {

    .heroText {
        width: 60% !important;
    }

    .headerMain {
        text-transform: capitalize;
        font-size: 35px;
        font-weight: 700;
    }
    
    .headerB {
        text-transform: capitalize;
        font-size: 27px;
        font-weight: 700;
    }
    
    .content, .infoContent, .servicesContent {
        font-size: 15px;
        font-weight: 300 !important;
        /* text-transform:uppercase; */
    }
    
    .servicesTitle, .stepName {
        font-size: 15px;
        font-weight: 450;
        margin: 0px;
        color: #ffffff;
    }
    
    .infoTitle {
        font-size: 15px;
        font-weight: 400;
        color: #fb8c03;
        margin: 0px;
    }
    
    .blogName {
        font-size: 17px;
        font-weight: 500;
        margin: 10px 0px;
    }

    .footerHead {
        font-size: 15px;
    }

    .feat {
        font-size: 15px;
    }

    .footerAA > div {
        font-size: 18px;
    }

    .footer {
        padding: 100px 10px 10px 10px;
    }

    .memberSliderControls {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 700px) {
    .heroText {
        width: 80% !important;
        text-align: center;
    }

    .heroText > .button  {
        margin: 30px auto;
    }

    .aboutH, .servicesH, .worksH, .teamH, .newsletter {
        flex-direction: column;
        align-items: center;
    }

    .aboutH > div {
        width: 95%;
    }

    .aboutHBB {
        height: 500px;
        gap: 20px;
    }

    .headerB {
        text-align: center;
        width: 100%;
    }

    .servicesHA, .servicesHB, .worksHA, .worksHB, .teamHA, .teamHB, .newsletterForm {
        width: 95%;
    }

    .newsletter > img {
        width: 100%;
    }

    .blogH {
        padding: 50px 10px;
    }

    .worksHB {
        height: 500px;
    }

    .worksHBbg {
        display: none;
    }

    .footerOne {
        flex-direction: column;
        gap: 30px;
    }

    .footerOne > div {
        width: 95%;
    }

    .blogCard > img {
        height: 300px;
    }

    /* .servicesControlH {
        display: none;
    } */

    .lbtnS, .projectsSliderControls {
        margin: 10px auto;
    }

    .fsB {
        margin-right: auto;
    }

    .testiBg > img {
        margin-left: 20px;
    }

    .testiCard {
        width: 80%;
    }

    .cTesti {
        line-height: 25px;
    }
}

@media only screen and (max-width: 500px) {
    .brOne {
        display: none;
    }

    .imgHolder > img {
        width: 40px;
        height: 40px;
    }


    .testiBg > img {
        margin-left: 0px;
    }

    .testiCard {
        width: 90%;
    }
}






