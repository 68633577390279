/* Global Styles */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
}

/* Container */
.terms-container {
    max-width: 1200px;
    margin: 0px auto;
    /* padding: 2rem; */
    background-color: #ffffff; /* White background for readability */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.terms-header {
    color: #ffffff; /* White text for contrast */
    padding: 1rem;
    border-radius: 8px 8px 0 0;
    text-align: center;
}

/* .terms-header h1 {
    margin: 0;
    font-size: 2.5rem;
} */

/* Main Content */
.terms-main {
    padding: 2rem;
}

.terms-main > h2 {
    color: #fa8c06; 
}

/* .terms-main > h2 {
    color: #fa8c06; 
    border-bottom: 2px solid #fa8c06;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.terms-main > p {
    font-size: 1rem;
    color: #333; 
    line-height: 1.6;
    margin-bottom: 1rem;
} */

ul {
    margin-left: 2rem;
}

ul li {
    margin-bottom: 0.5rem;
}

/* Footer */
.terms-footer {
    background-color: #f8f9fa;
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: 2rem;
}

.terms-main > p > a {
    color: inherit
}

.terms-footer p {
    margin: 0;
    color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
    .terms-container {
        padding: 1rem;
    }

    /* .terms-header h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
    } */
}

