@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.3s ease;
    text-decoration: none;
}

:root {
    --yellow: #fb8c03;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    scroll-padding-top: 7rem;
}

html::-webkit-scrollbar {
    width: 1.4rem;
}

html::-webkit-scrollbar-track {
    background: #222;
}

html::-webkit-scrollbar-thumb {
    background: var(--yellow);
}

::selection {
    /* background: var(--yellow); */
    color: #fff;
}

body {
    background: #fff;
    
}

.contactus--box {
    max-width: 120rem;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem;
    gap: 2rem;
    margin: 0 auto;
}

.contactus--image {
    flex: 1 1 30%;
    max-width: 30%;
    height: 42rem;
    overflow: hidden;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactus--image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.input--container {
    flex: 1 1 35%;
    max-width: 35%;
    padding: 2rem;
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start; /* Align items to the left */
}

.input--container h2 {
    text-transform: uppercase;
    color: #111;
    font-size: 3rem;
    margin-bottom: 2rem;
}

.input--container .box {
    padding: 1.5rem;
    background: #333;
    color: #fff;
    font-size: 1.6rem;
    border-radius: 5px;
    border: none;
    width: 100%; /* Ensure the input fields stretch to the full width */
}

.input--container .box.message {
    height: 10rem;
    resize: none;
}

.btn {
    padding: 1rem 4rem;
    background: #ffce00;
    color: #fff;
    cursor: pointer;
    border-radius: 5rem;
    border: none;
    font-size: 1.8rem;
    align-self: flex-start;
    transition: background 0.3s ease;
}

.btn i {
    padding-left: 0.5rem;
}

.btn:hover {
    background: #e6b800;
}

.contactinfo--container {
    flex: 1 1 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    text-wrap: wrap;
}

.contactinfo--container .contact,
.contactinfo--container .location,
.contactinfo--container .socialmedia {
    font-size: 1.6rem;
    color: #111;
    margin-top: 2rem;
    padding: 1rem;
}

.contactinfo--container > .contact > p > a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.contactinfo--container .socialmedia i {
    font-size: 2rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    color: hsl(251, 91%, 42%);
    transition: color 0.3s ease;
}

.contactinfo--container .socialmedia i:hover {
    color: #555;
}

@media (max-width: 1000px) {
    .contactus--box {
        flex-direction: column;
        align-items: center;
    }

    .contactus--image {
        display: none;
    }

    .input--container, .contactinfo--container {
        flex: 1 1 95%;
        max-width: 95%;
        display: flex;
        flex-direction: column;
    }
}
